<template>

    <div class="informationen">
        <h1 class="text-center">Spielgruppen&shy;pädagogik</h1>
        <p class="lead">Das Heranwachsen eines Kindes lässt sich sehr gut mit dem Gedeihen einer Pflanze vergleichen. Deshalb zeigen wir unsere Spielgruppenpädagogik in Form einer Blume auf.</p>

        <img alt="Spielgruppenpädagogik" src="../assets/img/informationen_paedagogik.png" class="noline no-margin-top">
        <figcaption>Spielgruppenpädagogik ig Spielgruppe Schweiz</figcaption>

        <div class="narrow">
        <ul> 
           <li>Der Boden symbolisiert die Familie, die dem Kind Halt gibt.</li>
            <li>Der Stil zeigt den Weg, welchen das Kind geht, bzw. das Wachsen.</li>
            <li>Die Blätter stellen die Entwicklung dar.</li>
            <li>Der Tropfen zeigt die Aufgaben der Spielgruppenleiterinnen. Sie sind aufmerksam, geduldig, empathisch und interessieren sich für die Kinder.</li>
            <li>Der Kern der Blume ist das Kind, denn <strong>das Kind steht im Mittelpunkt</strong>. Dies ist der wichtigste Grundsatz unserer Pädagogik. Die Bedürfnisse des Kindes stehen für uns an erster Stelle.</li>
        </ul>

            <p>
                Die Blüten symbolisieren weitere Aspekte, die für einen zufriedenen Aufenthalt in der Spielgruppe wichtig sind.
            </p>
            
        <ul> 
           <li>Das Kind wählt den Zeitpunkt passend zu seinem Entwicklungsstand. Man soll ihm Zeit lassen und es auf keinen Fall drängen, denn es merkt selbst wenn es bereit ist.</li>
           <li>Es vertraut in seine Fähigkeiten, wird selbständig und gewinnt an Selbstsicherheit. Wenn das Kind etwas kreiert, soll man sich bewusst sein, dass <strong>der Prozess viel wichtiger als das Produkt</strong> selber ist. Deshalb ist es auch wichtig, nicht zu werten.</li>
           <li>Kinder begreifen durch die verschiedenen Erfahrungen in der Spielgruppe. Dies tun sie mit all ihren Sinnen, deshalb auch der Grundsatz <strong>Begreifen durch Greifen.</strong></li>
           <li>Das Kind lernt mit und von anderen Kindern. Beim freien Spiel mit anderen Kinder übt es sich im sozialen Umfeld.</li>
           <li>Es entdeckt seine Stärken und traut sich selbstständig zu sein. Wir befolgen Maria Montessori’s Grundsatz – <strong>Hilf mir es sebst zu tun</strong>. So kann das Kind am meisten profitieren und erlang Selbständigkeit.</li>
           <li>Das Kind fühlt sich zugehörig und findet seinen Platz in der Gruppe. Erfahrungen mit anderen Kindern sind grundlegend in der Spielgruppe.</li>
           <li>Das Kind teilt sich mit, so findet die Sprachförderung statt, zum Beispiel durch Geschichten, Verse, Lieder etc.</li>
           <li>Das Kind lernt neuen Bezugspersonen zu vertrauen.</li>
        </ul>
        
        <p>
            Damit die Blume wächst braucht es die Sonne, welche Liebe und Wärme der Bezugspersonen symbolisiert. Für die Kinder ist die Spielgruppe meist der erste Kontakt ausserhalb die Familie mit einer anderen Bezugsperson. Die Aufgabe der Spielgruppenleiterinnen ist es die Kinder zu Selbstlernprozessen anzuregen, aber trotzdem gibt es in der Spielgruppe keinen Lehrplan, da dem Kind in dieser frühen Phase viel Freiraum gelassen wird um sich mit Freude an die neue Situation zu gewöhnen. Zudem liegt es uns am Herzen, dass Eltern und Spielgruppenleiterinnen eine offene Kommunikation pflegen. Dies wirkt sich sehr positiv auf das Kind aus. Wir sind uns bewusst, dass es nicht einfach ist, sein Kind jemandem zu überlassen, den man zu Beginn noch nicht kennt. Deshalb geben wir unser Bestes Ablösungsprozesse einfühlsam zu begleiten, damit wir gemeinsam ihrem Kind einen bestmöglichen Start gewähren können.
        </p>
        
        <p>
            Wir bedanken uns herzlich für ihr Vertrauen.
        </p>
            
        </div>

    </div>

</template>

<script>
export default {
    name: 'Informationen'
}
</script>
